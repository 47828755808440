import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/nprogress/nprogress.css";
import { EventBus } from "./services/event-bus";

// ADDING GLOBAL PROPERTIES
Vue.prototype.$eventBus = EventBus;
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.prototype.$localStorage = new Vue({
  data: {
    // token property returning the ls token value
    token: window.localStorage.getItem("loggedinUserToken"),
    fullName: window.localStorage.getItem("loggedinUserName"),
    showWarnig: false,
    connection: null,
  },
  watch: {
    // Watcher listening for changes on the token property
    // to ensure the new value is written back to localStorage
    token(value) {
      window.localStorage.setItem("loggedinUserToken", value);
    },
    fullName(value) {
      window.localStorage.setItem("loggedinUserName", value);
    },
  },
  created() {
    this.connect();

    // Listen for the beforeunload event to close the WebSocket connection
    window.addEventListener("beforeunload", this.closeWebSocket);
  },
  methods: {
    connect() {
      const self = this;
      this.connection = new WebSocket(process.env.VUE_APP_WS_URL);
      
      this.connection.onopen = function () {
        console.log("Socket Connected");
      };

      this.connection.onmessage = function (event) {
        let dataObj = JSON.parse(event.data);
        self.$eventBus.$emit("socket-event-header", dataObj);
        self.$eventBus.$emit("socket-event", dataObj);
      };

      this.connection.onerror = function () {
        console.log("Socket Error");
        self.connection.onclose();
      };

      this.connection.onclose = function () {
        console.log("Socket Connection Closed");
        setTimeout(function () {
          self.connect();
        }, 5000);
      };
    },
    closeWebSocket() {
      if (this.connection) {
        this.connection.close();
        console.log('WebSocket connection closed before unload');
      }
    }
  },
  beforeDestroy() {
    this.closeWebSocket();
    window.removeEventListener("beforeunload", this.closeWebSocket);
  },
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

