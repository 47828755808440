import axios from 'axios';
import { EventBus } from "./services/event-bus";
import NProgress from "nprogress";

const customInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Accept': 'application/json' }
})

customInstance.interceptors.request.use(config => {
  NProgress.start()
  return config
})

customInstance.interceptors.response.use(function (response) {
  NProgress.done()
  return response;
}, function (error) {
  NProgress.done()
  if (error && error.toJSON()?.message === 'Network Error') {
    let dataObj = { code: 200, data: true, facilityIds: [], id: 1683292630585, tags: [], type: "global-level-warning" };
    EventBus.$emit("socket-event-header", dataObj);
  }
  return Promise.reject(error);
});

export default customInstance;
