<template>
  <main>
    <section class="topsection">
      <div class="listviewbox favoritespage">
        <div class="row m-0">
          <div class="col-md-12 p-0">
            <div class="profilebox">
              <div class="dropdown">
                <span
                  class="dropdown-toggle"
                  type="button"
                  id="useraccount"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <b>Hi, {{ loggedinUserName }}</b>
                  <span
                    class="uname-cl"
                    v-bind:data-letters="loggedinUserName.charAt(0)"
                  ></span>
                </span>
                <div class="dropdown-menu" aria-labelledby="useraccount">
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    v-on:click="profileModal()"
                    >Account Details</a
                  >
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    v-on:click="logout()"
                    >Sign Out</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-md-5 p-0">
            <div class="listviewboxtop">
              <div
                class="searchBoxmain"
                v-bind:class="searchKeyEmpty ? 'searchBoxmainHover' : ''"
              >
                <form @submit.prevent="searchOnSubmitlist">
                  <input
                    type="text"
                    v-model="searchString"
                    @input="searcHhandlerlist"
                    placeholder="Search River system, Town, or Facility"
                  />
                  <span v-if="showbtnfaSearch">
                    <button type="submit"><i class="fa fa-search"></i></button>
                  </span>
                  <span v-if="showbtnfacross">
                    <button v-on:click="clearSearchBox()" type="button">
                      <i class="fa fa-times"></i>
                    </button>
                  </span>
                </form>
                <div v-show="isSearchResult">
                  <ul class="drodwn">
                    <li
                      v-for="searchItem of facilitiesSearch"
                      v-bind:key="searchItem.id"
                    >
                      <router-link :to="'/facility/' + searchItem.slug">
                        <span
                          :style="{
                            'font-weight':
                              searchItem.type === 1 ? 'bold' : '',
                          }"
                          >{{ searchItem.name }}</span
                        >
                        <span>{{ ", " }}</span>
                        <span
                          :style="{
                            'font-weight':
                              searchItem.type === 2 ? 'bold' : '',
                          }"
                          >{{ searchItem?.tb_riverSystem?.name }}</span
                        >
                        <span>{{ ", " }}</span>
                        <span
                          :style="{
                            'font-weight':
                              searchItem.type === 3 ? 'bold' : '',
                          }"
                          >{{ searchItem.nearTown }}</span
                        >
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <p>
                To ensure you have a
                <span>safe and enjoyable experience on the water</span>, view
                water levels, flow rates and safety information related to our
                hydropower facilities.
              </p>
            </div>
          </div>
          <div class="col-md-7 p-0">
            <div class="riverlist">
              <h1 class="headtext">My Favorites</h1>
              <div id="infinite-favourites" class="scroll">
                <div
                  class="viewrecent"
                  v-if="favoritesListArr && favoritesListArr.length"
                >
                  <div
                    v-for="(favoritesList, index) of favoritesListArr"
                    v-bind:key="favoritesList.id"
                  >
                    <div class="row rfacilities">
                      <div class="col-md-1">
                        <div
                          class="dropsicon"
                          v-bind:class="
                            favoritesList.tb_facility.alertCount > 0
                              ? 'orange-alert'
                              : favoritesList.tb_facility.notificationCount > 0
                              ? 'green-notification'
                              : ''
                          "
                        >
                          <img
                            src="../assets/images/water-drops.png"
                            alt="Facilities"
                          />
                        </div>
                      </div>
                      <div class="col-md-5">
                        <router-link
                          :to="'/facility/' + favoritesList.tb_facility.slug"
                        >
                          <h1>{{ favoritesList.tb_facility.name }}</h1>
                          <p>
                            <i class="fa fa-map-marker"></i> Near
                            {{ favoritesList.tb_facility.nearTown }}
                          </p>
                        </router-link>
                      </div>
                      <div class="col-md-6 favbtns">
                        <button
                          v-if="favoritesList.isEmailSubscribed == false"
                          class="subscribe"
                          v-on:click="
                            addToSubscribeEmail(favoritesList.id, true, index)
                          "
                        >
                          <i class="fa fa-envelope"></i> Subscribe
                        </button>

                        <button
                          v-if="favoritesList.isEmailSubscribed == true"
                          v-on:click="
                            addToSubscribeEmail(favoritesList.id, false, index)
                          "
                          class="subscribe subscribetrue"
                        >
                          <i class="fa fa-envelope"></i> Unsubscribe
                        </button>
                        <!-- <button class="subscribed">
                        <i class="fa fa-commenting"></i> Subscribed
                      </button> -->
                        <span class="dropdown">
                          <button
                            class="editfav dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span></span>
                            <span></span>
                            <span></span>
                          </button>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <a
                              class="dropdown-item"
                              v-on:click="
                                removeTofavorites(favoritesList.facilityId)
                              "
                              >Remove from Favorites</a
                            >
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-else>No favorites</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="modal fade"
      id="accdetails"
      tabindex="-1"
      role="dialog"
      aria-labelledby="accdetails"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body loginbox accdetailsbox">
            <h1>Account Details</h1>
            <form @submit.prevent="handleSubmit">
              <label>Full Name</label>
              <input
                type="text"
                v-model="user.firstName"
                id="firstName"
                name="firstName"
                :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"
              />
              <div
                v-if="submitted && !$v.user.firstName.required"
                class="invalid-feedback"
              >
                Full Name is required
              </div>

              <label>Email</label>
              <input
                disabled
                type="email"
                v-model="user.email"
                id="email"
                name="email"
                :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                autocomplete="off"
              />
              <div
                v-if="submitted && $v.user.email.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.user.email.required">Email is required</span>
                <span v-if="!$v.user.email.email">Email is invalid</span>
              </div>

              <label>Phone</label>
              <input type="text" v-model="user.phone" placeholder="Phone" />
              <hr />
              <label>Current Password</label>
              <div class="password">
                <input
                  type="password"
                  v-model="user.currentPwd"
                  id="currentPwd"
                  name="currentPwd"
                  autocomplete="off"
                  :class="{
                    'is-invalid': submitted && $v.user.currentPwd.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.currentPwd.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.user.currentPwd.required"
                    >Password is required</span
                  >
                </div>
              </div>

              <label>New Password</label>
              <div class="password">
                <input
                  type="password"
                  v-model="user.password"
                  id="password"
                  name="password"
                  autocomplete="off"
                  :class="{
                    'is-invalid': submitted && $v.user.password.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.password.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.user.password.minLength"
                    >Password must be at least 8 characters</span
                  >
                </div>
              </div>
              <div class="confimpassword">
                <label>Confim New Password</label>
                <input
                  type="password"
                  v-model="user.confirmPassword"
                  id="confirmPassword"
                  name="confirmPassword"
                  autocomplete="off"
                  :class="{
                    'is-invalid': submitted && $v.user.confirmPassword.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.confirmPassword.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.user.confirmPassword.sameAsPassword"
                    >Passwords must match</span
                  >
                </div>
              </div>
              <div class="detailsbtns">
                <label v-if="fullPage"></label>
                <input type="submit" name="" value="Submit" />
                <a href="#" data-dismiss="modal" aria-label="Close">Cancel</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import $ from "jquery";
import Vue from "vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Vuelidate from "vuelidate";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import ToasterService from "../services/toasterService";
import customInstance from "../config";
import { getFacilitySearchData } from "../services/commonFunctionService";

const toastMessage = new ToasterService();
Vue.use(Vuelidate);
Vue.use(Loading);
export default {
  data() {
    return {
      loggedinUserName: this.$localStorage.fullName,
      user: {
        firstName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        currentPwd: "",
      },
      submitted: false,
      fullPage: false,
      favoritesListArr: [],
      searchString: "",
      isSearchResult: false,
      facilitiesSearch: [],
      showbtnfaSearch: true,
      showbtnfacross: false,
      isEmailSubscribed: false,
      hasMoreFavourite: false,
      searchKeyEmpty: false,
      pageFavourite: 1,
      limit: 10,
    };
  },
  validations: {
    user: {
      firstName: { required },
      currentPwd: { required },
      email: { required, email },
      password: { minLength: minLength(8) },
      confirmPassword: { sameAsPassword: sameAs("password") },
    },
  },
  created() {
    if (this.$localStorage.token == "") {
      this.$router.push({ path: "/" });
    }
    this.getAllFavorites();
    document.title = "Favorites | SafeWaters by Brookfield Renewable US";
  },
  methods: {
    removeTofavorites(favid) {
      // const facid = this.$route.params.id;
      const access_token = this.$localStorage.token;
      customInstance
        .delete("/favourite/" + favid, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            toastMessage.success(res.data.data);
            (this.favoritesListArr = []),
              (this.pageFavourite = 1),
              this.getAllFavorites();
          }
          if (res.data.status == 400) {
            toastMessage.error(res.data.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addToSubscribeEmail(favID, status, index) {
      // alert(favID);
      const access_token = this.$localStorage.token;
      const body = { isEmailSubscribed: status };
      const headers = { Authorization: `Bearer ${access_token}` };

      customInstance
        .put("/favourite/" + favID + "/subscribe-email-sms", body, { headers })
        .then((res) => {
          if (res.data.status == 200) {
            if (status == true) {
              toastMessage.success("You have successfully subscribed.", {
                timeout: 2000,
                hideProgressBar: true,
              });
            } else {
              toastMessage.success("You have successfully unsubscribed.", {
                timeout: 2000,
                hideProgressBar: true,
              });
            }
            this.favoritesListArr[index].isEmailSubscribed = status;
          }
          if (res.data.status == 400) {
            toastMessage.error(res.data.data);
          }
        });
    },
    clearSearchBox() {
      this.showbtnfaSearch = true;
      this.showbtnfacross = false;
      this.isSearchResult = false;
      this.searchString = "";
      window.location.assign("/favorites");
    },

    searcHhandlerlist() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.searchString != "") {
          customInstance
            .get("/filterfacilities?search=" + this.searchString)
            .then((response) => {
              if (response.data.status == 200) {
                this.facilitiesSearch = getFacilitySearchData(
                  response.data.data
                );
                this.isSearchResult = true;
              }

              if (response.data.status == 400) {
                this.facilitiesSearch = [];
                this.isSearchResult = false;
              }
            })
            .catch((e) => {
              this.errors.push(e);
            });
        } else {
          this.isSearchResult = false;
        }
      }, 500);
    },

    searchOnSubmitlist(e) {
      if (this.searchString != "") {
        this.submitted = true;
        this.searchKeyEmpty = false;
        this.$router.push({ path: "list/search/" + this.searchString });
      } else {
        this.searchKeyEmpty = true;
        setTimeout(() => {
          this.searchKeyEmpty = false;
        }, 500);
      }
    },
    logout() {
      sessionStorage.clear();
      this.$localStorage.fullName = "";
      this.$localStorage.token = "";
      this.$router.push("/");
    },
    profileModal() {
      this.submitted = false;
      const access_token = this.$localStorage.token;
      $("#accdetails").modal("show");
      customInstance
        .get("/verifyDetails", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        .then((res) => {
          this.user = res.data.data;
          this.user.forEach((value, index) => {
            this.user.firstName = value.name;
            this.user.email = value.email;
            this.user.phone = value.phone;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    //update profile function
    handleSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (this.user.phone && !this.isNumberValid(this.user.phone)) {
        toastMessage.error("Phone number must contain only digits");
        return;
      }

      let loader = Vue.$loading.show();
      const access_token = this.$localStorage.token;
      const body = {
        name: this.user.firstName,
        phone: this.user.phone,
        password: this.user.password,
        currentPwd: this.user.currentPwd,
      };
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      customInstance.put("/verifyDetails", body, { headers }).then((res) => {
        if (res.data.status == 200) {
          loader.hide();
          localStorage.removeItem("loggedinUserName");
          localStorage.setItem("loggedinUserName", this.user.firstName);
          this.loggedinUserName = this.user.firstName;
          $("#accdetails").modal("hide");
          toastMessage.success("Profile update successfully.");
        }
        if (res.data.status == 400) {
          toastMessage.error(res.data.data);
          loader.hide();
        }
      });
    },
    //get favority list
    getAllFavorites: function () {
      let params = {
        limit: this.limit,
        page: this.pageFavourite,
      };
      const access_token = this.$localStorage.token;
      customInstance
        .get("/favourite/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          params,
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.favoritesListArr = this.favoritesListArr.concat(res.data.data);
            this.FavouriteLoaded();
            if (this.favoritesListArr.length >= res.data.totalCount) {
              this.hasMoreFavourite = false;
            } else {
              this.hasMoreFavourite = true;
            }
          }
          if (res.data.status == 400) {
            this.favoritesListArr = [];
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    loadMoreFavourite() {
      if (this.hasMoreFavourite) {
        this.pageFavourite = this.pageFavourite + 1;
        this.getAllFavorites();
      }
    },
    FavouriteLoaded() {
      this.$nextTick(() => {
        const listElm = document.querySelector("#infinite-favourites");
        listElm.addEventListener("scroll", (e) => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            this.loadMoreFavourite();
          }
        });
      });
    },
    isNumberValid(number) {
      const numberRegex = /^[0-9]+$/;
      return numberRegex.test(number);
    },
  },
};
</script>
<style type="text/css">
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #0f3557;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
  text-transform: uppercase;
  margin-left: 5px;
}
.uname-cl {
  margin: 2px;
}
.subscribetrue {
  background-color: #fae6cd !important;
  color: #e97f2b !important;
}
.subscribetrue:hover {
  background-color: #fae6cd !important;
  color: #e97f2b !important;
  border: 1px solid #e97f2b !important;
}
.viewrecent .rfacilities span.dropdown {
  display: inline-block;
}
.scroll {
  height: 705px;
  overflow-y: auto;
}
.viewrecent .rfacilities span.dropdown .dropdown-menu {
  background: #fff;
  box-shadow: 0 0 0 rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
  border-radius: 5px;
  border: 0;
  min-width: 116px;
  right: 89% !important;
  left: auto !important;
  transform: none !important;
  top: 100% !important;
}
</style>
