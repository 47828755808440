<template>
  <main>
    <section class="topsection">
      <div class="listviewbox">
        <div class="row m-0">
          <div class="col-md-5 p-0">
            <div class="listviewboxtop pb-0">
              <div
                class="searchBoxmain"
                v-bind:class="searchKeyEmpty ? 'searchBoxmainHover' : ''"
              >
                <form @submit.prevent="searchOnSubmitlist">
                  <input
                    type="text"
                    v-model="searchString"
                    @input="searcHhandlerlist"
                    placeholder="Search River system, Town, or Facility"
                  />
                  <span v-if="showbtnfaSearch">
                    <button type="submit"><i class="fa fa-search"></i></button>
                  </span>
                  <span v-if="showbtnfacross">
                    <button v-on:click="clearSearchBox()" type="button">
                      <i class="fa fa-times"></i>
                    </button>
                  </span>
                </form>
                <div v-show="isSearchResult">
                  <ul class="drodwn">
                    <li
                      v-for="searchItem of facilitiesSearch"
                      v-bind:key="searchItem.id"
                    >
                      <!--  <router-link :to="'/list?search=' + facilitiesSearchItem.name">{{ facilitiesSearchItem.name }}</router-link> -->
                      <a
                        href="javascript:void(0)"
                        v-on:click="sendToFacility(searchItem)"
                      >
                        <span
                          :style="{
                            'font-weight': searchItem.type === 1 ? 'bold' : '',
                          }"
                          >{{ searchItem.name }}</span
                        >
                        <span>{{ ", " }}</span>
                        <span
                          :style="{
                            'font-weight': searchItem.type === 2 ? 'bold' : '',
                          }"
                          >{{ searchItem?.tb_riverSystem?.name }}</span
                        >
                        <span>{{ ", " }}</span>
                        <span
                          :style="{
                            'font-weight': searchItem.type === 3 ? 'bold' : '',
                          }"
                          >{{ searchItem.nearTown }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <p>
                To ensure you have a
                <span>safe and enjoyable experience on the water</span>, view
                water levels, flow rates and safety information related to our
                hydropower facilities.
              </p>
            </div>

            <ul
              class="regionslist"
              v-if="showAllRegions"
              id="infinite-region-list"
            >
              <li>
                <a href="javascript:void(0)" class="active">All Regions </a>
              </li>
              <li v-for="item of regions" v-bind:key="item.id">
                <a
                  href="javascript:void(0)"
                  v-on:click="
                    selectedRegionHandler(item.slug);
                    setQueryParamsToUrl(1, item.slug);
                  "
                >
                  {{ item.name }}</a
                >
              </li>
            </ul>

            <div class="riverlist" v-if="showAllRiver">
              <a
                href="javascript:void(0)"
                v-on:click="backregionlist()"
                class="backlink"
                ><i class="fa fa-chevron-circle-left"></i>
                {{ selectedRegion }}</a
              >
              <h1 class="headtext">River Systems:</h1>

              <ul
                v-if="revirSystems && revirSystems.length"
                id="infinite-river-list"
              >
                <li v-for="listrevir of revirSystems" v-bind:key="listrevir.id">
                  <a
                    href="javascript:void(0);"
                    v-on:click="
                      selectedRiverSystemHandler(listrevir.slug);
                      setQueryParamsToUrl(2, listrevir.slug);
                    "
                  >
                    <span
                      v-bind:class="
                        listrevir.alertCount > 0
                          ? 'orange-alert'
                          : listrevir.notificationCount > 0
                          ? 'green-notification'
                          : ''
                      "
                      >{{ listrevir.facilityCount }}
                      <img src="../assets/images/wave-icon.png" alt="img"
                    /></span>
                    <p>
                      <b>{{ listrevir.name }}</b>
                      {{ listrevir.facilityCount }} Facilities
                    </p>
                  </a>
                </li>
              </ul>
            </div>

            <div class="riverlist" v-if="facilitiesShow">
              <a
                href="javascript:void(0)"
                class="backlink"
                v-on:click="backRiverSystem()"
                ><i class="fa fa-chevron-circle-left"></i>
                {{ selectedRiverSystem }}</a
              >

              <div class="viewrecent mapviewrsystem" id="infinite-facility-id">
                <div
                  v-for="facilitiesList of facilitiesObj"
                  v-bind:key="facilitiesList.id"
                >
                  <router-link
                    :to="'/facility/' + facilitiesList.slug"
                    v-if="
                      facilitiesList.alertCount == 0 &&
                      facilitiesList.notificationCount == 0
                    "
                  >
                    <div class="row rfacilities mapriversystem">
                      <div class="col-md-1">
                        <div class="dropsicon">
                          <img
                            src="../assets/images/water-drops.png"
                            alt="img"
                          />
                        </div>
                      </div>
                      <div class="col-md-11">
                        <h1>{{ facilitiesList.name }}</h1>
                        <p>
                          <i class="fa fa-map-marker"></i> Near
                          {{ facilitiesList.nearTown }}
                        </p>
                        <span>1-844-430-FLOW (3569)</span>
                      </div>
                      <div
                        class="col-md-11 offset-md-1 rfacilitiesmap"
                        v-for="(
                          matrices, index
                        ) of facilitiesList.tb_facilityMatrices"
                        v-bind:key="index"
                      >
                        <p>{{ matrices.description }}</p>
                        <h1>
                          {{ matrices.value || "0" }}
                          <small>{{ matrices.unit }}</small>
                        </h1>
                      </div>
                      <div class="col-md-11 offset-md-1 rfacilitiesmap">
                        <div class="viewmore">View more details</div>
                      </div>
                    </div>
                  </router-link>

                  <router-link
                    :to="'/facility/' + facilitiesList.slug"
                    v-if="
                      facilitiesList.alertCount == 0 &&
                      facilitiesList.notificationCount > 0
                    "
                  >
                    <div
                      class="row rfacilities mapriversystem"
                      style="border: 2px solid #509e2f !important"
                    >
                      <div class="noticationhead col-md-12 green-background">
                        <i class="fa fa-bell"></i>
                        <div>
                          <h1>{{ facilitiesList.facilityAlerts.title }}</h1>
                          <p>
                            {{
                              facilitiesList.facilityAlerts.updateOn
                                | formatDate1
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="dropsicon green-notification">
                          <img
                            src="../assets/images/water-drops.png"
                            alt="img"
                          />
                        </div>
                      </div>
                      <div class="col-md-11">
                        <h1>{{ facilitiesList.name }}</h1>
                        <p>
                          <i class="fa fa-map-marker"></i> Near
                          {{ facilitiesList.nearTown }}
                        </p>
                        <span>1-844-430-FLOW (3569)</span>
                      </div>
                      <div
                        class="col-md-11 offset-md-1 rfacilitiesmap"
                        v-for="(
                          matrices, index
                        ) of facilitiesList.tb_facilityMatrices"
                        v-bind:key="index"
                      >
                        <p>{{ matrices.description }}</p>
                        <h1>
                          {{ matrices.value || "0" }}
                          <small>{{ matrices.unit }}</small>
                        </h1>
                      </div>
                      <div class="col-md-11 offset-md-1 rfacilitiesmap">
                        <div class="viewmore">View more details</div>
                      </div>
                    </div>
                  </router-link>

                  <router-link
                    :to="'/facility/' + facilitiesList.slug"
                    v-if="facilitiesList.alertCount > 0"
                  >
                    <div class="row rfacilities mapriversystem other">
                      <div class="noticationhead col-md-12">
                        <i class="fa fa-exclamation-triangle"></i>
                        <div>
                          <h1>{{ facilitiesList.facilityAlerts.title }}</h1>
                          <p>
                            {{
                              facilitiesList.facilityAlerts.updateOn
                                | formatDate1
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="dropsicon">
                          <img
                            src="../assets/images/water-drops.png"
                            alt="img"
                          />
                        </div>
                      </div>
                      <div class="col-md-11">
                        <h1>{{ facilitiesList.name }}</h1>
                        <p>
                          <i class="fa fa-map-marker"></i> Near
                          {{ facilitiesList.nearTown }}
                        </p>
                        <span>1-844-430-FLOW (3569)</span>
                      </div>
                      <div
                        class="col-md-11 offset-md-1 rfacilitiesmap"
                        v-for="(
                          matrices, index
                        ) of facilitiesList.tb_facilityMatrices"
                        v-bind:key="index"
                      >
                        <p>{{ matrices.description }}</p>
                        <h1>
                          {{ matrices.value || "0" }}
                          <small>{{ matrices.unit }}</small>
                        </h1>
                      </div>
                      <div class="col-md-11 offset-md-1 rfacilitiesmap">
                        <div class="viewmore">View more details</div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 p-0">
            <div class="mapview">
              <l-map
                :bounds="bounds"
                style="height: 600px; width: 100%"
                v-if="showAllRegions"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker
                  v-for="(value, index) in markers"
                  :lat-lng="value.latlong"
                  v-on:click="
                    selectedRegionHandler(value.id);
                    setQueryParamsToUrl(1, value.id);
                  "
                  :visible="true"
                  v-bind:key="index"
                >
                  <l-tooltip :content="value.name" />
                  <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor">
                    <div class="headline">{{ value.count }}</div>
                    <div>
                      <img
                        src="../assets/images/wave-icon.png"
                        alt="img"
                        height="15"
                        width="25"
                      />
                    </div>
                  </l-icon>
                </l-marker>
              </l-map>

              <l-map
                :bounds="bounds"
                style="height: 600px; width: 100%"
                v-if="showAllRiver"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker
                  v-for="(value, index) in markers"
                  :lat-lng="value.latlong"
                  v-on:click="
                    selectedRiverSystemHandler(value.id);
                    setQueryParamsToUrl(2, value.id);
                  "
                  :visible="true"
                  v-bind:key="index"
                >
                  <l-tooltip :content="value.name" />
                  <l-icon
                    v-if="value.alertCount > 0"
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                    class-name="orange-alert"
                  >
                    <div class="headline">{{ value.count }}</div>
                    <div>
                      <img
                        src="../assets/images/wave-icon.png"
                        alt="img"
                        height="15"
                        width="25"
                      />
                    </div>
                  </l-icon>
                  <l-icon
                    v-else-if="value.notificationCount > 0"
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                    class-name="green-notification"
                  >
                    <div class="headline">{{ value.count }}</div>
                    <div>
                      <img
                        src="../assets/images/wave-icon.png"
                        alt="img"
                        height="15"
                        width="25"
                      />
                    </div>
                  </l-icon>
                  <l-icon
                    v-else
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                  >
                    <div class="headline">{{ value.count }}</div>
                    <div>
                      <img
                        src="../assets/images/wave-icon.png"
                        alt="img"
                        height="15"
                        width="25"
                      />
                    </div>
                  </l-icon>
                </l-marker>
              </l-map>

              <l-map
                :bounds="bounds"
                style="height: 600px; width: 100%"
                v-if="facilitiesShow"
              >
                <l-tile-layer :url="url" :attribution="attribution" />
                <l-marker
                  v-for="(value, index) in markers"
                  :lat-lng="value.latlong"
                  :visible="true"
                  v-bind:key="index"
                  v-on:click="setQueryParamsToUrl(3, value.id)"
                >
                  <l-tooltip :content="value.name" />
                  <l-icon
                    v-if="value.alertCount > 0"
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                    class-name="orange-alert"
                  >
                    <div class="headline">{{ value.alertCount }}</div>
                    <div>
                      <img
                        src="../assets/images/water-drops.png"
                        alt="img"
                        height="25"
                        width="25"
                      />
                    </div>
                  </l-icon>
                  <l-icon
                    v-else-if="value.notificationCount > 0"
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                    class-name="green-notification"
                  >
                    <div class="headline">{{ value.notificationCount }}</div>
                    <div>
                      <img
                        src="../assets/images/water-drops.png"
                        alt="img"
                        height="25"
                        width="25"
                      />
                    </div>
                  </l-icon>
                  <l-icon
                    v-else
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                  >
                    <div class="headline">{{ value.notificationCount }}</div>
                    <div>
                      <img
                        src="../assets/images/water-drops.png"
                        alt="img"
                        height="25"
                        width="25"
                      />
                    </div>
                  </l-icon>
                </l-marker>
              </l-map>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="safeinfo">
      <div class="aboutsafeinfo">
        <HomepageNote />
      </div>
    </section>
  </main>
</template>

<script>
// import axios from "axios";
import Vue from "vue";
import {
  LMap,
  LTileLayer,
  LLayerGroup,
  LPopup,
  LCircle,
  LMarker,
  LIcon,
  LTooltip,
} from "vue2-leaflet";
import { latLng, icon, fitBounds, latLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import HomepageSlider from "../components/homepageSlider";
import HomepageNote from "../components/homepageNote";
Vue.use(Loading);

import moment from "moment";
import customInstance from "../config";
import { getFacilitySearchData } from "../services/commonFunctionService";

Vue.filter("formatDate1", function (value) {
  if (value) {
    return moment(Number(value)).format("ddd, MMMM DD, hh:mm a");
  }
});
export default {
  name: "safewaterMap",
  components: {
    LMap,
    LTileLayer,
    LLayerGroup,
    LPopup,
    LCircle,
    LMarker,
    LIcon,
    latLng,
    HomepageSlider,
    HomepageNote,
    LTooltip,
  },
  data() {
    return {
      regions: [],
      revirSystems: [],
      markers: [],
      marzoom: [],
      facilitiesObj: [],
      showAllRegions: false,
      hasMoreRegion: false,
      pageRegion: 1,
      limit: 10,
      facilitiesShow: false,
      showAllRiver: false,
      minZoom: 2,
      zoom: 2,
      pageFacility: 1,
      facilityCounts: "",
      searchString: "",
      isSearchResult: false,
      facilitiesSearch: [],
      showbtnfaSearch: true,
      showbtnfacross: false,
      hasMorefacility: false,
      hasMoreRiver: false,
      searchKeyEmpty: false,
      bounds: latLngBounds([
        [40.70081290280357, -74.26963806152345],
        [40.82991732677597, -74.08716201782228],
      ]),
      pageRiver: 1,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      icon: icon({
        iconUrl: "",
        iconSize: [45, 45],
        iconAnchor: [45, 45],
      }),
      staticAnchor: [16, 37],
      facCount: "2",
      iconSize: 50,
      marker: latLng(45.367584, -68.972168),
      selectedRiverSystem: "",
      selectedRegion: "",
    };
  },
  methods: {
    clearSearchBox() {
      this.showbtnfaSearch = true;
      this.showbtnfacross = false;
      this.isSearchResult = false;
      this.searchString = "";
      setTimeout(() => window.location.assign("/map"), 1000);
    },

    searcHhandlerlist() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        if (this.searchString != "") {
          customInstance
            .get("/filterfacilities?search=" + this.searchString)
            .then((response) => {
              if (response.data.status == 200) {
                this.facilitiesSearch = getFacilitySearchData(
                  response.data.data
                );
                this.isSearchResult = true;
              }

              if (response.data.status == 400) {
                this.facilitiesSearch = [];
                this.isSearchResult = false;
              }
            })
            .catch((e) => {
              this.errors.push(e);
            });
        } else {
          this.isSearchResult = false;
        }
      }, 500);
    },

    searchOnSubmitlist(e) {
      if (this.searchString != "") {
        this.submitted = true;
        this.searchKeyEmpty = false;
        const search = this.searchString;
        const name = "Back";
        this.searchAllFacilities(search, name);

        this.isSearchResult = false;
        this.showbtnfaSearch = false;
        this.showbtnfacross = true;
        setTimeout(() => (this.isSearchResult = false), 1000);
      } else {
        this.searchKeyEmpty = true;
        setTimeout(() => {
          this.searchKeyEmpty = false;
        }, 500);
      }
    },

    getAllRiverSytems: function () {
      customInstance
        .get("/riverSytems/all", { params })
        .then((response) => {
          if (response.data.status == 200) {
            this.revirSystems = response.data.data;
          }
          if (response.data.status == 400) {
            this.revirSystems = [];
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    getAllRegionsMapView: function () {
      this.hasMoreRegion = false;
      let params = {
        limit: this.limit,
        page: this.pageRegion,
      };
      customInstance
        .get("/regions", { params })
        .then((response) => {
          if (response.data.status == 200) {
            this.regions = this.regions.concat(response.data.data);
            document.title =
              "Map of Regions | SafeWaters by Brookfield Renewable US";
            this.showAllRegions = true;
            this.showAllRiver = false;
            this.facilitiesShow = false;
            this.regionSystemLoaded();
            if (this.regions.length >= response.data.totalCount) {
              this.hasMoreRegion = false;
            } else {
              this.hasMoreRegion = true;
            }

            this.markers = [];
            this.marzoom = [];

            this.regions.forEach((value, index) => {
              if (value.lat != null && value.lng != null) {
                let newarr = {
                  latlong: [],
                };
                let newaa2 = [];
                newaa2.push(value.lat);
                newaa2.push(value.lng);
                newarr.latlong.push(value.lat);
                newarr.latlong.push(value.lng);
                newarr.id = value.slug;
                newarr.count = value.riverSysCount;
                newarr.name = value.name;
                this.markers.push(newarr);
                this.marzoom.push(newaa2);
              }
            });
            this.bounds = latLngBounds(this.marzoom);
          }
          if (response.data.status == 400) {
            this.regions = [];
            this.markers = [];
            this.showAllRegions = true;
            this.showAllRiver = false;
            this.facilitiesShow = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    backregionlist: function () {
      this.$router.push("/map");
    },

    selectedRegionHandler: function (id) {
      this.revirSystems = [];
      this.pageRiver = 1;
      this.getSingleRegions(id);
    },

    backRiverSystem() {
      if (this.$route.params.regionId != undefined) {
        this.revirSystems = [];
        this.pageRiver = 1;
        this.$router.push("/map/" + this.$route.params.regionId);
        this.getSingleRegions(this.$route.params.regionId);
      } else {
        this.regions = [];
        this.pageRegion = 1;
        this.$router.push("/map");
        this.getAllRegionsMapView();
      }
    },

    getSingleRegions: function (id) {
      let params = {
        limit: this.limit,
        page: this.pageRiver,
      };
      customInstance
        .get("/riverSytems/" + id, { params })
        .then((response) => {
          if (response.data.status == 200) {
            this.revirSystems = this.revirSystems.concat(response.data.data);
            this.selectedRegion = response.data.region.name;
            document.title =
              this.selectedRegion +
              " - Water Release Information | SafeWaters by Brookfield Renewable US";
            this.showAllRiver = true;
            this.showAllRegions = false;
            this.facilitiesShow = false;
            this.markers = [];
            this.marzoom = [];
            this.revirSystems.forEach((value, index) => {
              if (value.lat != null && value.lng != null) {
                let newarr = {
                  latlong: [],
                };
                let newaa2 = [];
                newaa2.push(value.lat);
                newaa2.push(value.lng);
                newarr.latlong.push(value.lat);
                newarr.latlong.push(value.lng);
                newarr.id = value.slug;
                newarr.count = value.facilityCount;
                newarr.alertCount = value.alertCount;
                newarr.notificationCount = value.notificationCount;
                newarr.name = value.name;
                this.markers.push(newarr);
                this.marzoom.push(newaa2);
              }
            });

            this.bounds = latLngBounds(this.marzoom);

            this.riverSystemLoaded();
            if (this.revirSystems.length >= response.data.totalCount) {
              this.hasMoreRiver = false;
            } else {
              this.hasMoreRiver = true;
            }
          }

          if (response.data.status == 400) {
            this.revirSystems = [];
            this.showAllRiver = true;
            this.showAllRegions = false;
            this.facilitiesShow = false;
            this.markers = [];
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    selectedRiverSystemHandler: function (id) {
      this.facilitiesObj = [];
      this.pageFacility = 1;
      this.getSinglefacility(id);
    },

    getSinglefacility(id) {
      let params = {
        limit: this.limit,
        page: this.pageFacility,
      };
      customInstance
        .get("/facilities?riverSystemId=" + id, { params })
        .then((response) => {
          if (response.data.status == 200) {
            this.facilitiesShow = true;
            this.showAllRiver = false;
            this.showAllRegions = false;

            this.facilitiesObj = this.facilitiesObj.concat(response.data.data);
            this.selectedRiverSystem = response.data.riverSysytem.name;
            document.title =
              this.selectedRiverSystem +
              ", " +
              this.facilitiesObj[0].region +
              " - Water Release Information | SafeWaters by Brookfield Renewable US";
            this.markers = [];
            this.marzoom = [];
            this.facilitiesObj.forEach((value, index) => {
              if (value.lat != null && value.lng != null) {
                let newarr = {
                  latlong: [],
                };
                let newaa2 = [];
                newaa2.push(value.lat);
                newaa2.push(value.lng);

                newarr.latlong.push(value.lat);
                newarr.latlong.push(value.lng);
                newarr.id = value.slug;
                newarr.alertCount = value.alertCount;
                newarr.notificationCount = value.notificationCount;
                newarr.name = value.name;
                this.markers.push(newarr);
                this.marzoom.push(newaa2);
              }
            });
            this.bounds = latLngBounds(this.marzoom);

            this.FacilityLoaded();

            if (this.facilitiesObj.length >= response.data.totalCount) {
              this.hasMorefacility = false;
            } else {
              this.hasMorefacility = true;
            }
          }
          if (response.data.status == 400) {
            this.facilitiesObj = [];
            this.markers = [];
            this.facilitiesShow = false;
            this.showAllRiver = false;
            this.showAllRegions = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    //search Facilities all
    searchAllFacilities: function (search, name) {

      customInstance
        .get("/facilities?search=" + search)
        .then((response) => {
          if (response.data.status == 200) {
            this.facilitiesShow = true;
            this.showAllRiver = false;
            this.showAllRegions = false;

            this.facilitiesObj = response.data.data;
            this.selectedRiverSystem =
              response.data.riverSysytem != null
                ? response.data.riverSysytem.name
                : "River System";

            this.markers = [];
            response.data.data.forEach((value, index) => {
              if (value.lat != null && value.lng != null) {
                let newarr = {
                  latlong: [],
                };
                let newaa2 = [];
                newaa2.push(value.lat);
                newaa2.push(value.lng);

                newarr.latlong.push(value.lat);
                newarr.latlong.push(value.lng);
                newarr.id = value.slug;
                newarr.alertCount = value.alertCount;
                newarr.notificationCount = value.notificationCount;
                newarr.name = value.name;
                this.markers.push(newarr);
                this.marzoom.push(newaa2);
              }
            });
            this.bounds = latLngBounds(this.marzoom);
            this.isSearchResult = false;
            this.showbtnfaSearch = false;
            this.showbtnfacross = true;
          }

          if (response.data.status == 400) {
            this.facilitiesObj = [];
            this.showAllRiver = false;
            this.showAllRegions = false;
            this.facilitiesShow = true;
            this.isSearchResult = false;
          }
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    setQueryParamsToUrl(type, id) {
      if (type == 1) {
        this.$router.push("/map/" + id);
      }

      if (type == 2) {
        this.$router.push("/map/" + this.$route.params.regionId + "/" + id);
      }

      if (type == 3) {
        this.$router.push("/facility/" + id);
      }
    },
    loadMoreRegionSystem() {
      if (this.hasMoreRegion) {
        this.pageRegion = this.pageRegion + 1;
        this.getAllRegionsMapView();
      }
    },
    regionSystemLoaded() {
      this.$nextTick(() => {
        const listElm = document.querySelector("#infinite-region-list");
        listElm.addEventListener("scroll", (e) => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            this.loadMoreRegionSystem();
          }
        });
      });
    },

    FacilityLoaded() {
      this.$nextTick(() => {
        const listElm = document.querySelector("#infinite-facility-id");
        listElm.addEventListener("scroll", (e) => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            this.loadMoreFacility();
          }
        });
      });
    },
    loadMoreFacility() {
      if (this.hasMorefacility) {
        this.pageFacility = this.pageFacility + 1;
        this.getSinglefacility(this.$route.params.riverSystemId);
      }
    },

    loadMoreReverSystem() {
      if (this.hasMoreRiver) {
        this.pageRiver = this.pageRiver + 1;
        this.getSingleRegions(this.$route.params.regionId);
      }
    },
    riverSystemLoaded() {
      this.$nextTick(() => {
        const listElm = document.querySelector("#infinite-river-list");
        listElm.addEventListener("scroll", (e) => {
          if (
            listElm.scrollTop + listElm.clientHeight >=
            listElm.scrollHeight
          ) {
            this.loadMoreReverSystem();
          }
        });
      });
    },
    sendToFacility(fac) {
      this.$router.push("/facility/" + fac.slug);
    },
  },

  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize];
      //return [this.iconSize, this.iconSize * 2.15];
    },
    dynamicAnchor() {
      return [this.iconSize, this.iconSize];
    },
  },

  created() {
    if (this.$route.params.riverSystemId != undefined) {
      this.getSinglefacility(this.$route.params.riverSystemId);
    } else if (this.$route.params.regionId != undefined) {
      this.getSingleRegions(this.$route.params.regionId);
    } else {
      this.getAllRegionsMapView();
    }
  },
  mounted() {
    this.$eventBus.$on("socket-event", (data) => {
      if (this.facilitiesShow) {
        if (data.type === "flow") {
          let index = this.facilitiesObj.findIndex(
            (item) => item.id === data.facilityId
          );
          if (index > -1) {
            const temp = data.data.sort(function (x, y) {
              return x.updateOn - y.updateOn;
            });
            this.facilitiesObj[index].tb_facilityMatrices = temp.slice(0, 2);
          }
        }

        if (data.type === "alert") {
          let index = this.facilitiesObj.findIndex(
            (item) => item.id === data.facilityId
          );
          if (index > -1) {
            const temp = data.data.sort(function (x, y) {
              return y.updateOn - x.updateOn;
            });

            let alertArray = temp.filter((val) => {
              return val.type === "Alert";
            });

            let notificationArray = temp.filter((val) => {
              return val.type === "Notifications";
            });
            this.facilitiesObj[index].alertCount = alertArray.length;
            this.facilitiesObj[index].notificationCount =
              notificationArray.length;
            this.facilitiesObj[index].facilityAlerts =
              alertArray.length > 0
                ? alertArray[0]
                : notificationArray.length > 0
                ? notificationArray[0]
                : {};
          }
        }
      }
    });
  },
  watch: {
    $route: function (to, from) {
      if (from.path !== "/map" && to.path === "/map") {
        this.regions = [];
        this.pageRegion = 1;
        this.getAllRegionsMapView();
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off("socket-event");
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to
/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.leaflet-marker-icon {
  background: #3581f7;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 4px 6px;
  border: 1px solid #3581f7;
  box-shadow: 0px 0px 0px 1px #fff inset;
  margin-top: 3px;
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 141.68%;
  letter-spacing: -0.01em;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  margin: 0 16px 0 0;
}

.backlinklist-mapView {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125.18%;
  color: #0f3557;
  padding: 2px 11px 20px 4px;
  display: block;
}

.backlinklist-mapView .fa {
  color: #509e2f;
}
</style>
